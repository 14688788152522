// REACT
import React, { useState } from "react"
// MUI
import { Typography, MobileStepper, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons"
// SWIPEABLE VIEWS
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"

const useStyles = makeStyles({
  root: {
    maxWidth: 800,
    flexGrow: 1,
    width: "100%",
    margin: "2rem auto",
  },
  img: {
    display: "block",
    maxWidth: 800,
    overflow: "hidden",
    width: "100%",
  },
  stepper: {
    backgroundColor: "#ffffff",
  },
})

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

// COMPONENT FUNCTION
export const ImageSlider = ({ steps, maxWidth = 800 }) => {
  // STYLE
  const classes = useStyles()

  // STATE
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = steps.length

  // HANDLERS
  const handleNext = () => {
    setActiveStep(prev => prev + 1)
  }

  const handleBack = () => {
    setActiveStep(prev => prev - 1)
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }

  // COMPONENT
  return (
    <div className={classes.root} style={{ maxWidth: maxWidth }}>
      <AutoPlaySwipeableViews
        axis={"x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {steps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img
                className={classes.img}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Typography variant="caption" align="center" display="block">
        {steps[activeStep].label}
      </Typography>
      <MobileStepper
        className={classes.stepper}
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
    </div>
  )
}
